import springConfigurationCache from '@/cache/springConfiguration.cache.js';

export const springConfigurationStore = {
	state: {
		name: 'spring_configuration',
		title: 'Spring Configuration',
		titlePlural: 'Spring Configurations',
		nav: {
			base: 'spring_configuration',
			newView: true,
			hideUpload: true,
			views: [
				{
					view: 'duplication',
					label: 'Duplication',
					alwaysVisible: true
				},
				{
					view: 'details',
					label: 'Details'
				}
			]
		},
		search: {
			endPoint: 'spring_configurations',
			formFields: {
				unit_model_num: {
					val: '',
					qsField: '[unit_model_num]',
					label: 'Title',
					component: 'SearchTypeaheadInput',
					cdiType: 'pm_model'
				},
				configuration_type: {
					val: 'ALL',
					qsField: '[configuration_type]',
					label: 'Type',
					component: 'SearchSelectInput',
					valuesOnly: true,
					excludeDefault: true,
					options: ['ALL'].concat(springConfigurationCache.CONFIGURATIONS),
				},
				conforming: {
					val: 0,
					qsField: '[conforming]',
					label: 'Conforming',
					component: 'SearchCheckboxInput'
				},
				enabledOnly: {
					val: 1,
					qsField: '[enabled_only]',
					label: 'Enabled Only',
					component: 'SearchCheckboxInput'
				},
				searchIndex:{
					val: 0,
					qsField: '[search_index]'
				},
			},
			resultFields: {
				spring_configuration_id: {header: 'Configuration ID', link: 'spring_configuration'},
				unit_model_num: {header: 'Unit Model', link: ''},
				spring_location: {header: 'Spring Location', link: ''},
				raw_material: {header: 'Spring Material', link: ''},
				configuration_type: {header: 'Type', link: ''},
				conforming: {header: 'Conforming', link: ''}
			}
		},
		object: {
			fields: [
				{
					name: 'spring_configuration_id',
					label: 'Spring Configuration ID',
					component: 'DetailsStatic'
				},
				{
					name: 'unit_model_num',
					label: 'Model',
					component: 'DetailsStatic'
				},
				{
					name: 'spring_location',
					label: 'Spring Location',
					valuesOnly: true,
					excludeDefault: true,
					component: 'DetailsSelect',
					options: springConfigurationCache.LOCATIONS
				},
				{
					name: 'raw_material_id',
					label: 'Weight Capacity',
					component: 'DetailsSelect',
					excludeDefault: true,
					options: springConfigurationCache.TYPES
				},
				{
					name: 'configuration_type',
					label: 'Configuration Type',
					component: 'DetailsSelect',
					excludeDefault: true,
					valuesOnly: true,
					options: springConfigurationCache.CONFIGURATIONS
				},
				{
					name: 'conforming',
					label: 'Conforming',
					component: 'DetailsCheckbox'
				},
				{
					name: 'disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				}
			]
		}
	}
}