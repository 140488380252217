<template>
    <div>
        <h1>Configuration Duplication</h1>
        <form @submit.prevent="lookup()">
            <div class="panel panel-default toggle_container">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Model</label>
                        <Typeahead cdi_type="pm_model" v-bind:ID.sync="lookupModel" />
                    </div>

                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Type to duplicate</label>
                        <select class="form-control" v-model="configurationType">
                            <option value="ALL">ALL</option>
                            <option v-for="(option, index) in cache.springConfigurationCache.CONFIGURATIONS" :key="index" :value="option">{{ option }}</option>
                        </select>
                    </div>

                    <div class="col-md-3 col-sm-4 col-xs-6 form-group form_grid">
                        <label><input type="checkbox" v-model="conforming" :true-value="1" :false-value="''"> Conforming</label>
                    </div>
                </div>
            </div>

            <input type="submit" class="btn btn-primary btn-lg btn_min_width" value="Lookup">
        </form>

        <div v-if="relatedModels.length > 0">
            <h3>Result for: {{ lookupModel }}</h3>
            <em>Hold shift and click one checkbox and then another to select a group of checkboxes</em>

            <form @submit.prevent="duplicate()">
                <div class="row">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-for="(row, index) in relatedModels" v-bind:key="row.unit_model_id" :value="row.unit_model_id">
                        <label>
                            {{ row.unit_model_num }}
                            <input type="checkbox" v-model="modelsToUpdate" :value="row.unit_model_id" @click.shift="multiCheck(index)">
                        </label>
                    </div>
                </div>

                <input type="submit" class="btn btn-primary btn-lg btn_min_width" value="Duplicate Configurations">
            </form>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import springConfigurationCache from '@/cache/springConfiguration.cache.js';

    export default {
        name: "Duplication",
        components: {Typeahead},
        data() {
            return {
                state: store.state,
                cache: {
                    springConfigurationCache
                },
                startCheckboxIndex: null,
                lookupModel: '',
                relatedModels: [],
                modelsToUpdate: [],
                configurationType: 'ALL',
                conforming: false
            }
        },
        methods: {
            duplicate: function () {
                let options = {
                    'params[unit_model_num]': this.lookupModel,
                    'params[configuration_type]': this.configurationType,
                    'params[duplication_model_ids]': this.modelsToUpdate
                };

                store.api('duplicate', options);
            },
            multiCheck: function (index) {
                if (this.startCheckboxIndex === null) {
                    this.startCheckboxIndex = index;

                    return;
                }

                let start = this.startCheckboxIndex;
                let end = index;
                this.relatedModels.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(row => {
                    if (!this.modelsToUpdate.includes(row.unit_model_id)) {
                        this.modelsToUpdate.push(row.unit_model_id);
                    }
                });

                this.startCheckboxIndex = null;
            },
            lookup: function () {
                let options = {
                    'params[unit_model_num]': this.lookupModel,
                    'params[configuration_type]': this.configurationType
                };

                store.customApi('model/lookup_models', options).then(results => this.relatedModels = results);
            }
        }
    }
</script>

<style scoped>

</style>