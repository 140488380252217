<template>
    <div>
        <Search v-if="isCurrentView('search') && !id" :showMoreButton="false" />
        <New v-else-if="isCurrentView('new') && !id" />
        <Duplication v-else-if="isCurrentView('duplication')" />
        <div v-else>
            <h1>Spring Configurations</h1>
            <Details :data="state.object.data" v-show="isCurrentView('details')" />
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store.js";
    import { springConfigurationStore } from "@/components/tools/SpringConfiguration/SpringConfiguration.store";
    import Search from "@/components/businessObjects/utils/Search";
    import Details from "@/components/businessObjects/utils/Details";
    import New from "@/components/tools/SpringConfiguration/New";
    import Duplication from "@/components/tools/SpringConfiguration/Duplication";

    export default {
        name: "Container",
        data() {
            return {
                state: store.state,
                springConfigurationState: springConfigurationStore.state,
            }
        },
        props: ["id"],
        components: {
            Duplication,
            New,
            Search,
            Details
        },

        created() {
            store.initialize(this.springConfigurationState);
            this.load();
        },
        methods: {
            load: function() {
                if (this.id) {
                    store.load(this.id)
                        .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.spring_configuration_id}`]));
                }
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            }
        },
        computed: {
            currentView: function() {
                return this.state.currentView;
            }
        },
        watch: {
            id: function () {
                this.load();
            }
        }
    }
</script>

<style scoped>

</style>