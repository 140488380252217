<template>
    <div>
        <h1>New Configuration</h1>
        <form @submit.prevent="save">
            <div class="panel panel-default toggle_container">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Model</label>
                        <!-- TODO need to clear this out upon submission -->
                        <Typeahead cdi_type="pm_model" v-bind:ID.sync="formFields.unitModelNum.val" />
                    </div>

                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Type</label>
                        <select class="form-control" v-model="formFields.type.val">
                            <option v-for="(option, index) in cache.springConfigurationCache.CONFIGURATIONS" :key="index" :value="option">{{ option }}</option>
                        </select>
                    </div>

                    <div class="col-md-3 col-sm-4 col-xs-6 form-group form_grid">
                        <label><input type="checkbox" v-model="formFields.conforming.val" :true-value="1" :false-value="''"> Conforming</label>
                    </div>
                </div>
            </div>
            <div>
                <div class="table-heading">
                    <h5><em>Leaving a location blank is the same as selecting "None"</em></h5>
                </div>
                <div class="table">
                    <tr>
                        <td class="form-group" v-for="location in cache.springConfigurationCache.ROW_1_LOCATIONS" v-bind:key="location" :value="location">
                            <label>{{ location }}</label>
                            <select class="form-control"  v-model="formFields.locations[location]">
                                <option value="0" selected>None</option>
                                <option v-for="[rawMaterialID, weightCapacity] in cache.springConfigurationCache.TYPES" v-bind:key="weightCapacity" :value="rawMaterialID">
                                {{ weightCapacity }}
                                </option>
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td class="form-group">
                            <label>{{ cache.springConfigurationCache.ROW_2_LOCATIONS[0] }}</label>
                            <select class="form-control"  v-model="formFields.locations[cache.springConfigurationCache.ROW_2_LOCATIONS[0]]">
                                <option value="0" selected>None</option>
                                <option v-for="[rawMaterialID, weightCapacity] in cache.springConfigurationCache.TYPES" v-bind:key="weightCapacity" :value="rawMaterialID">
                                    {{ weightCapacity }}
                                </option>
                            </select>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="form-group">
                            <label>{{ cache.springConfigurationCache.ROW_2_LOCATIONS[1] }}</label>
                            <select class="form-control"  v-model="formFields.locations[cache.springConfigurationCache.ROW_2_LOCATIONS[1]]">
                                <option value="0" selected>None</option>
                                <option v-for="[rawMaterialID, weightCapacity] in cache.springConfigurationCache.TYPES" v-bind:key="weightCapacity" :value="rawMaterialID">
                                    {{ weightCapacity }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td class="form-group" v-for="location in cache.springConfigurationCache.ROW_3_LOCATIONS" v-bind:key="location" :value="location">
                            <label>{{ location }}</label>
                            <select class="form-control"  v-model="formFields.locations[location]">
                                <option value="0" selected>None</option>
                                <option v-for="[rawMaterialID, weightCapacity] in cache.springConfigurationCache.TYPES" v-bind:key="weightCapacity" :value="rawMaterialID">
                                    {{ weightCapacity }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </div>
            </div>


            <input type="submit" class="btn btn-primary btn-lg btn_min_width" value="Save">
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import helpers from "@/store/helpers";
    import springConfigurationCache from '@/cache/springConfiguration.cache.js';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "New",
        components: {Typeahead},
        data() {
            return {
                state: store.state,
                cache: {
                    springConfigurationCache
                },
                formFields: this.getDefaultFormData()
            }
        },
        created() {
            store.clearObjectData();
        },
        methods: {
            save: function() {
                let options = helpers.getQSPairs(this.formFields);

                Object.entries(this.formFields.locations).forEach((row) => {
                    const [key, value] = row;
                    options[`params[${key}][raw_material_id]`] = value;
                });

                store.api('new_configuration', options).then(result => {
                    if (result.opt === 'save') {
                        this.formFields = this.getDefaultFormData();
                    }
                });
            },
            getDefaultFormData: function() {
                return {
                    unitModelNum: { val: '', qsField: '[unit_model_num]' },
                    type: { val: 'BASE', qsField: '[configuration_type]' },
                    conforming: { val: '', qsField: '[conforming]' },
                    locations: { }
                }
            }
        },
    }
</script>

<style scoped>
</style>